<template>
  <v-dialog v-model="newDialog" persistent max-width="500">
    <v-card :loading="loading" loader-height="2">
      <v-card-title>new certification</v-card-title>
      <v-card-text>
        <v-text-field
          dense
          label="first name"
          v-model="add.first_name"
        ></v-text-field>
        <v-text-field
          dense
          label="last name"
          v-model="add.last_name"
        ></v-text-field>
        <v-text-field
          dense
          label="mobile phone"
          v-model="add.mobile_phone"
        ></v-text-field>
        <v-text-field
          dense
          label="email"
          type="email"
          v-model="add.email"
        ></v-text-field>
        <v-select
          label="role"
          v-model="add.rolesId"
          :items="rolelist"
          item-value="id"
          item-text="name"
        ></v-select>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              NO
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              text
              color="blue"
              v-on="on"
              @click="submit"
              :disabled="$v.add.$invalid"
            >
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
import { validationMixin } from "vuelidate";
import {
  required,
  decimal,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  props: {
    newDialog: Boolean,
  },
  // mixins: [Utils],
  data() {
    return {
      loading: false,
      rolelist: [],
      add: {
        first_name: null,
        last_name: null,
        mobile_phone: null,
        email: null,
        rolesId: 3,
      },
    };
  },
  validations: {
    add: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      mobile_phone: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      email: {
        required,
        email,
      },
      rolesId: {
        required,
        decimal,
      },
    },
  },
  created() {
    this.roles();
  },
  watch: {},
  methods: {
    roles() {
      this.loading = true;
      let self = this;
      Restful.general.roles
        .list()
        .then((response) => {
          console.log(response.data);
          this.rolelist = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    closeDialog() {
      let payload = { state: false };
      this.$emit("closeNew", payload);
    },
    submit() {
      let first_name = this.add.first_name;
      let last_name = this.add.last_name;
      let mobile_phone = this.add.mobile_phone;
      let email = this.add.email;
      let rolesId = this.add.rolesId;
      let data = {
        first_name,
        last_name,
        mobile_phone,
        email,
        rolesId,
      };
      console.log("data:", data);
      let payload = { state: false, data: data };
      this.$emit("submitNew", payload);
    },
  },
};
</script>
