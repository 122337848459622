<template>
  <v-dialog v-model="activeDialog" persistent max-width="500">
    <v-card :loading="loading" loader-height="2">
      <v-card-title>update service</v-card-title>
      <v-card-text>
        <v-switch
          v-model="add.active"
          :label="`Active: ${add.active.toString()}`"
        ></v-switch>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              text
              color="blue"
              v-on="on"
              @click="submit"
              :disabled="$v.add.$invalid"
            >
              submit
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Utils from "../../mixins/utils";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  mixins: [Utils, validationMixin],
  props: {
    activeDialog: Boolean,
    myId: { type: Number },
    active: { type: Boolean },
  },
  data() {
    return {
      loading: false,
      add: { active: this.active },
    };
  },
  validations: {
    add: {
      active: {
        required,
      },
    },
  },
  methods: {
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeActive", payload);
    },
    submit() {
      let active = this.add.active;
      let data = { active };
      // console.log("data:", data);
      let payload = { id: this.myId, data: data, state: false };
      this.$emit("submitActive", payload);
    },
  },
};
</script>
