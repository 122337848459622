<template>
  <div class="page">
    <div class="heading">
      <v-toolbar dense :flat="flatapp">
        <v-btn icon @click="menulink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs
          :items="crumbs"
          style="padding: 14px 20px"
          class="capitalize"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn x-small text color="primary" @click.stop="newDialog = true">
          new user
        </v-btn>
        <UserNew
          :newDialog="newDialog"
          @closeNew="closeNew"
          @submitNew="submitNew"
        />
        <v-btn icon color="primary" @click="refresh">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body">
      <v-card flat>
        <v-card-text>
          <v-data-table
            :loading="loading"
            loader-height="1"
            :headers="headers"
            :items="userlist"
            :items-per-page="10"
          >
            <template v-slot:item.user.active="{ item }">
              <v-btn icon @click.stop="$set(activeDialog, item.user.id, true)">
                <v-icon :color="item.user.active ? 'primary' : 'error'">
                  {{ item.user.active ? "mdi-check" : "mdi-close" }}
                </v-icon>
              </v-btn>
              <UserActive
                v-if="activeDialog[item.user.id]"
                :activeDialog="activeDialog[item.user.id]"
                :myId="item.user.id"
                :active="item.user.active"
                @closeActive="closeActive"
                @submitActive="submitActive"
              />
            </template>

            <!-- <template v-slot:item.user.id="{ item }">
              <v-btn x-small text>details => {{ item.user.id }}</v-btn>
            </template> -->
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
    <v-snackbar
      :color="snack.color"
      v-model="snack.bar"
      :multi-line="snack.multiLine"
      :timeout="snack.timeout"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import WinScroll from "../../mixins/windowscroll";
import Utils from "../../mixins/utils";
import UserNew from "../../components/general/add_user.vue";
import UserActive from "../../components/general/active.vue";
export default {
  components: { UserNew, UserActive },
  mixins: [Utils, WinScroll("position")],
  data() {
    return {
      newDialog: false,
      activeDialog: {},
      snack: {
        color: "info",
        multiLine: true,
        bar: false,
        timeout: 12000,
        text: "Processing ... ",
      },
      crumbs: [
        {
          exact: true,
          link: true,
          text: this.capitalize(localStorage.getItem("user.busines")),
        },
      ],
      loading: false,
      userlist: [],
      headers: [
        { text: "first name", value: "user.first_name" },
        { text: "last name", value: "user.last_name" },
        { text: "email", value: "user.email" },
        { text: "mobile phone", value: "user.mobile_phone" },
        { text: "active", value: "user.active" },
        // { text: "action", value: "id" },
      ],
    };
  },
  computed: {
    flatapp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.list();
  },
  methods: {
    list() {
      this.loading = true;
      let self = this;
      Restful.accounts.mySubs
        .subscriberUsers()
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.userlist = response.data;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    refresh() {
      this.list();
    },
    menulink() {
      this.$emit("sidebar");
    },
    closeNew(p) {
      this.newDialog = p.state;
    },
    submitNew(p) {
      console.log(p);
      this.snack.bar = true;
      let self = this;
      Restful.accounts
        .add(p.data)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.newDialog = p.state;
    },
    closeActive(p) {
      this.$set(this.activeDialog, p.id, p.state);
    },
    submitActive(p) {
      this.snack.bar = true;
      let self = this;
      Restful.accounts
        .active(p.id, p.data)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.$set(this.activeDialog, p.id, p.state);
    },
  },
};
</script>
